import React from 'react';
import {Grid} from "@material-ui/core";
import "./footer.scss"
import { useTranslation } from 'react-i18next';

function Footer() {
    const { t, i18n } = useTranslation();

    return (
        <footer className={"pt-15 pb-15 text-white"}>
            <Grid container alignItems={"center"} justify={"center"}>
                <Grid item>square media</Grid>
            </Grid>
        </footer>
    );
}

export default Footer;