import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Breadcrumbs} from "@material-ui/core";
import "./LanguageChanger.scss";
const languages = {
    'sk-SK':'Sk',
    'en-US':'En',
    'de-DE':'De'
}
function LanguageChanger(props) {
    const { t, i18n } = useTranslation( );
    const [currentLanguage, setCurrentLanguage] = useState(undefined);
    useEffect(()=>{
        if(!Object.keys(languages).includes(currentLanguage) && currentLanguage !== undefined){
            setCurrentLanguage(i18n?.options?.fallbackLng.length > 0 ? i18n?.options?.fallbackLng[0]: languages[0]);
        }
        i18n.changeLanguage(currentLanguage);
    },[currentLanguage])

    useEffect(()=>{
        if(i18n.language !== undefined){
            //console.log(i18n.language)
            setCurrentLanguage(i18n.language);
        }
    },[])
    return (
        <Breadcrumbs color={"primary"} aria-label="breadcrumb">
            <span className={"oneLang " + (currentLanguage === 'sk-SK' || !Object.keys(languages).includes(currentLanguage) ? 'activeLang': '')} onClick={()=>setCurrentLanguage('sk-SK')}>
                {languages["sk-SK"]}
            </span>
            <span className={"oneLang " + (currentLanguage === 'en-US' ? 'activeLang': '')} onClick={()=>setCurrentLanguage('en-US')}>
                {languages["en-US"]}
            </span>
            <span className={"oneLang " + (currentLanguage === 'de-DE' ? 'activeLang': '')} onClick={()=>setCurrentLanguage('de-DE')}>
                {languages["de-DE"]}
            </span>
        </Breadcrumbs>
    );
}

export default LanguageChanger;