import {BrowserRouter, Route, Switch} from "react-router-dom";
import {createMuiTheme, CssBaseline, responsiveFontSizes} from "@material-ui/core";
import {ThemeProvider} from "@material-ui/styles";
import ReactGA from "react-ga";
import React, {useEffect, useState, Suspense} from "react";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Home from "./pages/Home/Home";
import './App.scss';
import './components/i18n';

function initializeReactGA() {
  ReactGA.initialize('');
  ReactGA.pageview(window.location.pathname + window.location.search);
}

function App() {
  let theme = createMuiTheme({
    breakpoints:{
      values:{
        sm: 650,
        md: 900
      }
    },
    palette:{
      primary:{
        main: "#141414"
      },
      secondary: {
        main: "#8b4f26"
      },
      type: "light"
    },
    typography: {
      fontFamily: "'Montserrat', sans-serif !important",
      body1:{
        fontWeight: 400,
        fontFamily: "'Montserrat', sans-serif",
      },
      h1: {
        fontSize: "4rem"
      },
      h2: {
        fontSize: "2.5rem",
        '@media (max-width:450px)': {
          fontSize: '1.2rem',
        },
        fontWeight: 700
      },
      h3:{
        fontSize: "2rem",
      }

    },
    shape:{
      borderRadius: 0
    }

  });
  theme = responsiveFontSizes(theme);
  useEffect(()=>{
    initializeReactGA()
  },[])
  const [headerHidden, setHeaderHidden] = useState(true);
  useEffect(()=>{
    window.addEventListener('scroll', scrollHandler);
    return () => {
        window.removeEventListener('scroll', scrollHandler);
    }
  },[])
  const scrollHandler = () =>{
    if(window.pageYOffset >= 50){
      setHeaderHidden(false);
    }else if(window.pageYOffset <= 0){
      //setHeaderHidden(true);
    }
  }
  return (
      <>
        <Suspense fallback="loading">
        <BrowserRouter>
            <CssBaseline>
              <ThemeProvider theme={theme}>
                <Header hidden={headerHidden}/>
                <Switch>
                  <Route exact path="/"><Home /></Route>
                </Switch>
                <Footer/>
              </ThemeProvider>
            </CssBaseline>
        </BrowserRouter>
        </Suspense>
      </>
  );
}

export default App;