import React from 'react';
import {Grid, Typography, useTheme} from "@material-ui/core";
import mainimage from "../../images/main.jpg"
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';
import GavelOutlinedIcon from '@material-ui/icons/GavelOutlined';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined';
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import nehnutelnostImage from "../../images/nehnutelnost.jpg"
import obcianskeImage from "../../images/obcianske.jpg"
import obchodneImage from "../../images/obchodne.jpg"
import dedicskeImage from "../../images/dedicske.jpg"
import workImage from "../../images/work.jpg"
import familyImage from "../../images/family.jpg"
import CustomCard from "../../components/CustomCard/CustomCard";
import "./home.scss";
import {useTranslation} from "react-i18next";
import AOS from 'aos';
import 'aos/dist/aos.css';
import tncastle from "../../images/tncastle.jpg";

AOS.init();
function Home() {
    const { t, i18n } = useTranslation();
    return (
        <div className={"bgtnimage"}>
            <Grid container alignItems={"flex-start"} justify={"center"} direction={"column"}
                  className={"bg-img-container"} style={{backgroundImage: "url('"+mainimage+"')", paddingTop: 70}}>
                <Grid item><Typography data-aos={"fade-right"} component={"h2"} variant={"h1"} className={"text-white fs-40 pl-20 pr-45 fw-weight-600 max-w-800"}>{t('Komplex law services and consultations since 2012')}</Typography>
                </Grid>
                <Grid item className={"mt-15 mb-5 ls-2"}>
                    <Typography data-aos={"fade-right"} component={"h3"} variant={"h2"} className={"text-white fs-16 pl-55 pr-50 "}>
                        <a href="mailto:jjanajanikova@gmail.com">
                            <Grid container alignItems={"center"}>
                                <MailIcon/>
                                <Grid item className={"pl-15"}>jjanajanikova@gmail.com</Grid>
                            </Grid>
                        </a>
                    </Typography>
                </Grid>
                <Grid item className={"mb-10 ls-2"}>
                    <Typography data-aos={"fade-right"} component={"h3"} variant={"h2"} className={"text-white fs-16 pl-55 pr-50 "}>
                        <a href="tel:+421 949 811 793">
                            <Grid container alignItems={"center"}>
                                <PhoneIcon/>
                                <Grid item className={"pl-15"}>+421 949 811 793</Grid>
                            </Grid>
                        </a>
                    </Typography>
                </Grid>
            </Grid>
            <Grid container direction={"column"} style={{minHeight: "50vh", position:"relative"}}>
                <div className={"bg-half-secondary"}/>
                <Grid item className={"mt-50 pb-px-20 mw-12"}>
                    <Typography data-aos={"zoom-out"} className={"text-white text-center fs-32 fs-min-32 pb-22 pr-5 pl-5"} variant={"h3"} component={"h2"}>

                    </Typography>
                </Grid>
                <Grid item container justify={"center"} spacing={6} className={"mw-14 front"}>
                    <Grid item xs={12} sm={6} md={4} data-aos={"zoom-out"} data-aos-delay="100">
                        <CustomCard title={t("Civil law")} image={obcianskeImage} icon={<GavelOutlinedIcon/>}>
                            <Typography className={"fs-min-16 fs-16"} component={"p"}>{t("civil1")}</Typography>
                            <Typography className={"fs-min-16 fs-16"} component={"p"}>{t("civil2")}</Typography>
                        </CustomCard>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} data-aos={"zoom-out"} data-aos-delay="200">
                        <CustomCard title={t("Business law")} image={obchodneImage} icon={<BusinessCenterOutlinedIcon/>}>
                            <Typography className={"fs-min-16 fs-16"} component={"p"}>{t("business1")}</Typography>
                            <Typography className={"fs-min-16 fs-16"} component={"p"}>{t("business2")}</Typography>
                        </CustomCard>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} data-aos={"zoom-out"} data-aos-delay="300">
                        <CustomCard title={t("Family law")} image={familyImage} icon={<PeopleOutlineIcon/>}>
                            <Typography className={"fs-min-16 fs-16"} component={"p"}>{t("family1")}</Typography>
                            <Typography className={"fs-min-16 fs-16"} component={"p"}>{t("family2")}</Typography>
                        </CustomCard>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} data-aos={"zoom-out"} data-aos-delay="100">
                        <CustomCard title={t("Real estate law")} image={nehnutelnostImage} icon={<AccountBalanceOutlinedIcon/>}>
                            <Typography className={"fs-min-16 fs-16"} component={"p"}>{t("real_estate1")}</Typography>
                            <Typography className={"fs-min-16 fs-16"} component={"p"}>{t("real_estate2")}</Typography>
                        </CustomCard>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} data-aos={"zoom-out"} data-aos-delay="200">
                        <CustomCard title={t("Inheritance law")} image={dedicskeImage} icon={<img src={"/images/cert.svg"}/>}>
                            <Typography className={"fs-min-16 fs-16"} component={"p"}>{t("inheritance1")}</Typography>
                            <Typography className={"fs-min-16 fs-16"} component={"p"}>{t("inheritance2")}</Typography>
                            <Typography className={"fs-min-16 fs-16"} component={"p"}>{t("inheritance3")}</Typography>
                        </CustomCard>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} data-aos={"zoom-out"} data-aos-delay="300">
                        <CustomCard title={t("Labor law")} image={workImage}  icon={<WorkOutlineOutlinedIcon/>}>
                            <Typography className={"fs-min-16 fs-16"} component={"p"}>{t("labor1")}</Typography>
                            <Typography className={"fs-min-16 fs-16"} component={"p"}>{t("labor2")}</Typography>
                            <Typography className={"fs-min-16 fs-16"} component={"p"}>{t("labor3")}</Typography>
                        </CustomCard>
                    </Grid>
                </Grid>
            </Grid>
            <div className={"bg-full-secondary mt-50"} id={"contact"}>
                <Grid container className={"mw-14 p-40"} direction={"column"}>
                    <Grid item className={"mb-15"}>
                        <Typography variant={"h2"} component={"h2"} className={"fs-38 fs-min-42 text-white"}>{t('Contact')}</Typography>
                    </Grid>
                    <Grid item container spacing={5}>
                        <Grid item container xs={12} sm={6} direction={"row"} style={{minHeight: "300px", height:"auto"}}>
                            <Grid item xs={12} style={{height:"100%", width: "100%"}}>
                                <div style={{overflow:"hidden",resize:"none",maxWidth:"100%", width:"100%", height:"100%", minHeight: "100%"}}>
                                    <div style={{height:"100%", width:"100%", maxWidth:"100%"}}>
                                        <iframe data-aos={"fade-in"} style={{height:"100%", width:"100%", border:0}} frameBorder="0" title={"map"}
                                                src="https://www.google.com/maps/embed/v1/place?q=K+dolnej+stanici+7282/20A,+911+01+Trenčín&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8">
                                        </iframe>
                                    </div>
                                </div>
                            </Grid>

                        </Grid>
                        <Grid item container xs={12} sm={6} direction={"row"} style={{textAlign:"right"}}>
                            <Grid item container direction={"column"} className={"contact-info"} justify={"space-evenly"}>
                                <Grid item container direction={"column"} className={"pb-15"}>
                                    <Grid item><Typography className={"text-white fw-weight-600"} variant={"h2"} component={"p"}>{t('Address')}</Typography></Grid>
                                    <Grid item><Typography className={"text-white"} variant={"body1"} component={"p"}>K dolnej stanici 7282/20A, 911 01 Trenčín</Typography></Grid>
                                    <Grid item><Typography className={"text-white"} variant={"body1"} component={"p"}>{t("(building of the Trenčín self-governing region)")}</Typography></Grid>
                                </Grid>
                                <Grid item>
                                    <Typography variant={"body1"} component={"p"} className={"text-white ftext"}>
                                        <b>{t('We provide our clients with comprehensive legal services at a professional level')}</b>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

export default Home;