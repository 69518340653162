import React, {useState} from 'react';
import {
    AppBar,
    Button, Collapse,
    Drawer, Grid,
    IconButton,
    List,
    MenuItem,
    Toolbar,
    Typography,
    useMediaQuery,
    useTheme
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import LanguageChanger from "../LanguageChanger/LanguageChanger";
import logo from "../../images/logo.png"


const useStyles = makeStyles((theme) =>({

    menuIndex: {
        zIndex: theme.zIndex.drawer + 1
    },
    wholeDrawer:{
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white
    },
    drawerContainer:{
        overflow: 'auto',
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white
    },
    vyplnit:{
        flexGrow: 1,
    },
    linkLogo: {
        color: "white",
        textDecoration: "none",
        display: "flex",
        alignItems: "center"
    },
    fullWidth: {
        width: "100%",
    },
    logoImage: {
        height: 70,
    },
    toolBarHeight:{
        height: 70,
    }


}));

const menus = [
//    {url:"/test", title:"test"},
];
function Header(props) {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const [isOpenedMenu, setIsOpenedMenu] = useState(matches);
    const closeMenu = () => {
        setIsOpenedMenu(false);
    }
    const toggleMenu = () =>{
        setIsOpenedMenu(!isOpenedMenu);
    }

    return (
        <>

            <AppBar className={classes.menuIndex} color={"secondary"} position={"fixed"}>
                <Collapse in={true}>
                <Toolbar className={classes.toolBarHeight} >
                    <Link to={"/"} className={classes.linkLogo} onClick={closeMenu}>
                        <img src={logo} alt="" style={{height: "60px"}}/>
                        <Grid container justify={"center"} direction={"column"} style={{paddingLeft: "15px",marginLeft: "5px", borderLeft: "1px solid white"}}>
                            <Grid item><Typography variant={"body1"} component={"p"} className={"fs-12 fs-min-12"}>{t("Lawyer's office")}</Typography></Grid>
                            <Grid item><Typography variant={"h2"} component={"h1"} className={"fs-16 fs-min-16"}>JUDr. Jana Janíková</Typography></Grid>
                        </Grid>
                    </Link>
                    <div className={classes.vyplnit}/>
                    {matches ? ( <IconButton onClick={toggleMenu}>
                            <MenuIcon style={{ color: "white" }} />
                        </IconButton>
                    ) : (<>
                    {menus.map((menuItem) => (
                            <Button key={menuItem.url} component={Link} to={menuItem.url} style={{color: "white"}}>{menuItem.title}</Button>
                        ))}
                            <LanguageChanger/>
                        </>
                    )}
                </Toolbar>
                </Collapse>
            </AppBar>
            {matches &&(

                <Drawer anchor={'top'}
                        open={isOpenedMenu}
                        onClose={closeMenu}
                        ModalProps={{ onBackdropClick: closeMenu }}
                        variant="persistent"
                        classes={{
                            paper: classes.wholeDrawer
                        }}
                >
                    <Toolbar className={classes.toolBarHeight}/>
                    <div className={classes.drawerContainer}>
                        <List>
                            {menus.map((menuItem) => (
                                <MenuItem key={menuItem.url} component={Link} to={menuItem.url} className={classes.fullWidth} onClick={closeMenu}>
                                    {menuItem.title}
                                </MenuItem>
                            ))}
                            <p style={{paddingLeft:16, paddingRight:16}} className={classes.fullWidth} onClick={closeMenu}><LanguageChanger/></p>

                        </List>
                    </div>
                </Drawer>

            )}
        </>
    );
}

export default Header;