import React from 'react';
import {
    Card,
    CardActionArea,
    CardMedia, Grid,
    Typography
} from "@material-ui/core";
import "./customCard.scss";
function CustomCard(props) {
    return (
        <Card className={"oneService"}>
            <CardActionArea className={"disableCursor"} disabled style={{backgroundColor: "#000"}}>
                <Grid container alignItems={"center"} className={"service-header pl-12 text-white"} >
                    {props.icon}
                    <Grid item><Typography className={"text-white fs-22 fs-min-22 fw-weight-500 pl-5 pt-8 pb-8"} component={"p"}>{props.title}</Typography></Grid>
                </Grid>
                <CardMedia
                    className={"customCardMedia"}
                    image={props.image}
                    title={props.title}
                >
                    <div className={"overtext"}>
                        {props.children}
                    </div>
                </CardMedia>
            </CardActionArea>
        </Card>
    );
}

export default CustomCard;